<template>
   <section id="page-dashboard" class="mt-8">
      <v-card color="white" elevation="12" class="pa-2 mx-6 mb-6" outlined tile>
         <v-card-title class="align-start">
            <v-sheet color="accent" class="mt-n10" style="z-index: 1;" elevation="6" rounded>
               <v-icon dark size="52" class="pa-6">
                  mdi-view-dashboard
               </v-icon>
            </v-sheet>
         </v-card-title>
         <v-card flat outlined tile class="pull-top">
            <div class="grey lighten-4 pa-6 pt-5 space-left d-flex space-between">
               <div>
                  <base-title title="Live Dashboard" class="text-uppercase mt-n1" space="0" />
                  <base-subtitle title="Fill all mandatory fields marks with asterisk *." />
               </div>
               <v-spacer />
               <v-select v-model="type" :items="filteredCategories" label="Category" placeholder="Pilih Kategori" outlined hide-details class="mt-n1 ml-4" style="max-width: 300px;"
                  :return-object="false" :menu-props="{ bottom: true, offsetY: true }" :disabled="!filteredCategories.length"
                  @change="changeCategory"
                  />
               <v-select v-model="dashboard" :items="filteredDashboards" label="Dashboard" placeholder="Pilih Dashboard" outlined hide-details class="mt-n1 ml-4" style="max-width: 300px;"
                  item-text="text"
                  :return-object="true" :menu-props="{ bottom: true, offsetY: true }" :disabled="!filteredDashboards.length"
                  @change="changeDashboard"
                  />
            </div>
            <v-row class="mt-0">
               <v-col cols="12" class="pt-0">
                  <v-slide-group class="pa-1" show-arrows="desktop">
                     <v-slide-item v-for="(shortcut, idx) in shortcuts" :key="idx">
                        <v-card v-if="$hasRole(shortcut.link.params.kind.toLowerCase(), 'R')" :color="tooBright(shortcut.color) ? 'secondary' : 'grey lighten-4'" class="ma-1 px-2 d-flex" height="64" max-width="180"
                           @click="$router.push(shortcut.link)"
                           >
                           <v-icon x-large left :color="shortcut.color">
                              {{ shortcut.icon }}
                           </v-icon>
                           <v-list-item-content :style="`color: ${shortcut.color}`">
                              <v-list-item-title class="text-h6 font-weight-light mb-0" style="line-height: 1.4rem;">
                                 {{ shortcut.title }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                 {{ shortcut.description }}
                              </v-list-item-subtitle>
                           </v-list-item-content>
                        </v-card>
                        <div v-else />
                     </v-slide-item>
                  </v-slide-group>
               </v-col>
               <!--
               <v-col v-if="isUsed" cols="12" class="pt-0 d-flex">
                  <v-toolbar flat height="80">
                     <v-spacer />
                     <v-btn x-large depressed outlined class="mx-2" @click="generateRandom">
                        Random Data
                     </v-btn>
                     <v-btn x-large depressed outlined class="mx-2" @click="addMoreSamples">
                        Append Data
                     </v-btn>
                     <v-spacer />
                  </v-toolbar>
               </v-col>
               -->
            </v-row>
            <v-row v-if="dashboard.kind && $hasRole(dashboard.kind.toLowerCase(), 'G')" class="px-4" style="min-height: 418px;">
               <template v-if="dashboard.kind === 'Presence'">
                  <v-col cols="6" class="pt-1">
                     <v-slide-y-reverse-transition mode="out-in">
                        <n-line-approval :records="records" :sample="sample" title="Visitor Activities" :excel="excel" @import="importChart" @export="exportChart" @random="generateRandom" @append="addMoreSamples" />
                     </v-slide-y-reverse-transition>
                  </v-col>
                  <v-col cols="6" class="pt-1">
                     <v-slide-y-reverse-transition mode="out-in">
                        <n-bar-presence :records="records" :sample="sample" title="Visitor Transactions" :excel="excel" @import="importChart" @export="exportChart" @random="generateRandom" @append="addMoreSamples" />
                     </v-slide-y-reverse-transition>
                  </v-col>
               </template>
            </v-row>
         </v-card>
      </v-card>
   </section>
</template>

<script>
import { pastel, luminance } from '@/utils/nirtara'
// const _ = require('lodash')
const getRandomInt = function () {
   return Math.floor(Math.random() * (50 - 5 + 1)) || 10
}
export default {
   name: 'SectionPageDashboard',

   components: {
      // NBarChart: () => import('@/charts/NBarChart'),
      NBarPresence: () => import('@/charts/NBarPresence'),
      // NStackedBarChart: () => import('@/charts/NStackedBarChart'),
      // NLineChart: () => import('@/charts/NLineChart'),
      NLineApproval: () => import('@/charts/NLineApproval'),
      // NPieChart: () => import('@/charts/NPieChart'),
      // NPolarAreaChart: () => import('@/charts/NPolarAreaChart'),
      // NHorizontalBarChart: () => import('@/charts/NHorizontalBarChart'),
      // NDoughnutChart: () => import('@/charts/NDoughnutChart'),
   },

   data: () => ({
      isUsed: false,
      type: '',
      dashboards: [
         // { type: 'Person', kind: 'Member', text: 'Member Activities', id: -1 },
         // { type: 'Activity', kind: 'Approval', text: 'Visitor Approval', id: -1 },
         // { type: 'Activity', kind: 'Invitation', text: 'Tenant Invitation', id: -1 },
         { type: 'Transaction', kind: 'Presence', text: 'Visitor Presence', id: -1 },
      ],
      dashboard: {},
      records: [],

      sample: [],
      rows: [],
      excel: { importing: false, exporting: false },

      shortcuts: [
         { title: 'Approval', description: 'Visit Approvals', icon: 'mdi-shield-check', color: pastel(1), link: { name: 'Activities', params: { kind: 'Approval', id: -1 } } },
         { title: 'Room', description: 'Manage Rooms', icon: 'mdi-card-outline', link: { name: 'Locations', params: { kind: 'Room', id: -1 } } },
         { title: 'Floor', description: 'Manage Floors', icon: 'mdi-stairs', link: { name: 'Locations', params: { kind: 'Floor', id: -1 } } },
         { title: 'Building', description: 'Manage Buildings', icon: 'mdi-office-building', link: { name: 'Locations', params: { kind: 'Building', id: -1 } } },
         { title: 'Receptionist', description: 'Tenant Telegrams', icon: 'mdi-telegram', color: pastel(1), link: { name: 'Persons', params: { kind: 'Receptionist', id: -1 } } },
         { title: 'Member', description: 'Manage Members', icon: 'mdi-account', link: { name: 'Persons', params: { kind: 'Member', id: -1 } } },
         { title: 'Global', description: 'Manage Global', icon: 'mdi-hammer-wrench', link: { name: 'Settings', params: { type: 'Option', kind: 'Global', id: -1 } } },
      ],
   }),

   computed: {
      filteredCategories () {
         return this.dashboards.filter(j => this.$hasRole(j.kind.toLowerCase(), 'G')).map(j => j.type).filter((value, index, self) => self.indexOf(value) === index)
      },
      filteredDashboards () {
         return this.dashboards.filter(j => j.type === this.type && this.$hasRole(j.kind.toLowerCase(), 'G'))
      },
      tooBright () {
         return hex => luminance(hex) > 155
      },
   },
   created () {
      this.$waitSetting(zone => {
         // this.$socket.on(`client/${this.$store.state.zone}/Person`, this.handleRecord)
         // this.$socket.on(`client/${this.$store.state.zone}/Location`, this.handleRecord)
         // this.$socket.on(`client/${this.$store.state.zone}/Subject`, this.handleRecord)
         this.$socket.on(`client/${this.$store.state.zone}/Activity`, this.handleRecord)
         this.$socket.on(`client/${this.$store.state.zone}/Transaction`, this.handleRecord)
      })
   },
   mounted () {
      const params = (this.$route.params.type && this.$route.params.kind && this.$route.params.text) ? this.$route.params : this.dashboards.find(j => j.kind === 'Presence') || {}
      this.type = params.type
      this.changeDashboard(params)
   },
   destroyed () {
      // this.$socket.off(`client/${this.$store.state.zone}/Person`, this.handleRecord)
      // this.$socket.off(`client/${this.$store.state.zone}/Location`, this.handleRecord)
      // this.$socket.off(`client/${this.$store.state.zone}/Subject`, this.handleRecord)
      this.$socket.off(`client/${this.$store.state.zone}/Activity`, this.handleRecord)
      this.$socket.off(`client/${this.$store.state.zone}/Transaction`, this.handleRecord)
   },
   methods: {
      changeCategory (type) {
         switch (type) {
            case 'Person': this.changeDashboard(this.dashboards.find(j => j.kind === 'Member' || j.type === type))
               break
            case 'Transaction': this.changeDashboard(this.dashboards.find(j => j.kind === 'Presence' || j.type === type))
               break
         }
      },
      changeDashboard (json) {
         this.dashboard = Object.assign({}, json)
         this.selectRecord()
      },
      async selectRecord (kind = this.dashboard.kind) {
         if (kind === 'Presence') {
            var data = {
               conditions: { kind: kind },
               headers: { command: 'chartReport' },
            }
            const response = await this.$store.dispatch('mongoose', data)
            // console.log('selectRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
            const activities = response.data.filter(j => Object.keys(j).some(s => ['register', 'rejected', 'enrolled', 'accepted'].includes(s)))
            Array.from({ length: 7 }, (_, i) => {
               const date = this.$moment().subtract(i, 'days').format('YYYY-MM-DD')
               !activities.find(j => j.date === date) && activities.push({ date })
            })
            activities.sort((a, b) => a.date.localeCompare(b.date))
            const transactions = response.data.filter(j => Object.keys(j).some(s => ['gate', 'lift'].includes(s)))
            Array.from({ length: 7 }, (_, i) => {
               const date = this.$moment().subtract(i, 'days').format('YYYY-MM-DD')
               !transactions.find(j => j.date === date) && transactions.push({ date })
            })
            transactions.sort((a, b) => a.date.localeCompare(b.date))
            this.records = [
               {
                  type: 'Line',
                  legend: { register: 'Register', rejected: 'Rejected', enrolled: 'Enrolled', accepted: 'Accepted' },
                  result: activities,
               },
               {
                  type: 'Bar',
                  legend: { gate: 'Qrcode on Gate', lift: 'Qrcode on Lift' },
                  result: transactions,
               },
            ]
            // console.log('selectRecord (this.records)', JSON.parse(JSON.stringify(this.records)))
         } else this.generateRandom()
      },
      generateRandom () {
         switch (this.dashboard.kind) {
            case 'Presence':
               this.records = [
                  {
                     type: 'Line',
                     legend: { register: 'Register', rejected: 'Rejected', enrolled: 'Enrolled', accepted: 'Accepted' },
                     result: [
                        { date: this.$moment().add(6 * -1, 'days').format('YYYY-MM-DD'), register: getRandomInt(), rejected: getRandomInt(), enrolled: getRandomInt(), accepted: getRandomInt() },
                        { date: this.$moment().add(5 * -1, 'days').format('YYYY-MM-DD'), register: getRandomInt(), rejected: getRandomInt(), enrolled: getRandomInt(), accepted: getRandomInt() },
                        { date: this.$moment().add(4 * -1, 'days').format('YYYY-MM-DD'), register: getRandomInt(), rejected: getRandomInt(), enrolled: getRandomInt(), accepted: getRandomInt() },
                        { date: this.$moment().add(3 * -1, 'days').format('YYYY-MM-DD'), register: getRandomInt(), rejected: getRandomInt(), enrolled: getRandomInt(), accepted: getRandomInt() },
                        { date: this.$moment().add(2 * -1, 'days').format('YYYY-MM-DD'), register: getRandomInt(), rejected: getRandomInt(), enrolled: getRandomInt(), accepted: getRandomInt() },
                        { date: this.$moment().add(1 * -1, 'days').format('YYYY-MM-DD'), register: getRandomInt(), rejected: getRandomInt(), enrolled: getRandomInt(), accepted: getRandomInt() },
                        { date: this.$moment().add(0 * -1, 'days').format('YYYY-MM-DD'), register: getRandomInt(), rejected: getRandomInt(), enrolled: getRandomInt(), accepted: getRandomInt() },
                     ],
                  },
                  {
                     type: 'Bar',
                     // type: 'StackedBar',
                     // legend: { presence: 'Presence' },
                     legend: { gate: 'Qrcode on Gate', lift: 'Qrcode on Lift' },
                     result: [
                        // { date: this.$moment().add(9 * -1, 'days').format('YYYY-MM-DD'), gate: getRandomInt(), lift: getRandomInt() },
                        // { date: this.$moment().add(8 * -1, 'days').format('YYYY-MM-DD'), gate: getRandomInt(), lift: getRandomInt() },
                        // { date: this.$moment().add(7 * -1, 'days').format('YYYY-MM-DD'), gate: getRandomInt(), lift: getRandomInt() },
                        { date: this.$moment().add(6 * -1, 'days').format('YYYY-MM-DD'), gate: getRandomInt(), lift: getRandomInt() },
                        { date: this.$moment().add(5 * -1, 'days').format('YYYY-MM-DD'), gate: getRandomInt(), lift: getRandomInt() },
                        { date: this.$moment().add(4 * -1, 'days').format('YYYY-MM-DD'), gate: getRandomInt(), lift: getRandomInt() },
                        { date: this.$moment().add(3 * -1, 'days').format('YYYY-MM-DD'), gate: getRandomInt(), lift: getRandomInt() },
                        { date: this.$moment().add(2 * -1, 'days').format('YYYY-MM-DD'), gate: getRandomInt(), lift: getRandomInt() },
                        { date: this.$moment().add(1 * -1, 'days').format('YYYY-MM-DD'), gate: getRandomInt(), lift: getRandomInt() },
                        { date: this.$moment().add(0 * -1, 'days').format('YYYY-MM-DD'), gate: getRandomInt(), lift: getRandomInt() },
                     ],
                  },
               ]
               break
         }
      },
      addMoreSamples () {
         this.sample = [getRandomInt(), getRandomInt(), getRandomInt(), getRandomInt(), getRandomInt()]
      },
      async importChart (buffer) {
         const workbook = await new this.$excelJS.Workbook().xlsx.load(buffer)
         console.log('importChart (workbook)', workbook)

         workbook.eachSheet(worksheet => {
            worksheet.eachRow((row, idx) => {
               const values = row.values.slice(1)
               if (idx === 1) {
                  console.log('importChart (header)', values)
               } else {
                  console.log('importChart (values)', values)
               }
            })
         })

         setTimeout(() => {
            this.excel.importing = false
         }, 2400)
      },
      async exportChart () {
         const workbook = new this.$excelJS.Workbook()
         workbook.creator = 'NVM-50-WEB'
         console.log('exportChart (workbook)', workbook)

         const worksheet = workbook.addWorksheet('NVM-50-WEB')
         worksheet.columns = [
            { header: 'Site', key: 'site' },
            { header: 'Kind', key: 'kind' },
            { header: 'Name', key: 'name' },
            { header: 'Array', key: 'array' },
            { header: 'Object', key: 'object' },
         ]
         worksheet.addRow({ site: this.$site, kind: 'Member', name: 'Administrator', array: '[]', object: '{}' })
         this.$excelJS.autoWidth(worksheet)

         const saveAs = require('file-saver')
         const buffer = await workbook.xlsx.writeBuffer()
         const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
         saveAs(blob, 'nvm50web.xlsx')

         setTimeout(() => {
            this.excel.exporting = false
         }, 2400)
      },
      handleRecord (item) {
         // console.log('handleRecord (item)', JSON.parse(JSON.stringify(item)))
         item.Content.forEach(record => {
            if (record.kind === 'Approval') {
               // const date = record.createdAt.slice(0, 10)
               const status = record.Activity[0].activity.toLowerCase()
               const date = record.Activity[0].Approval.find(j => j.name === record.Activity[0].activity).since.slice(0, 10)
               const idx = this.records.findIndex(j => j.type === 'Line')
               const line = this.records[idx]
               const activities = line.result
               // console.log(`handleRecord ( status = ${status} )`, JSON.parse(JSON.stringify(activities)))
               const activity = activities.find(j => j.date === date)
               if (activity) {
                  if (activity[status]) activity[status] = parseInt(activity[status]) + 1
                  else activity[status] = 1
               } else {
                  activities.push({ date, [status]: 1 })
                  activities.splice(0, activities.length - 7)
               }
               // console.log('handleRecord (line)', JSON.parse(JSON.stringify(line)))
               this.records.splice(idx, 1, line)
            }
            if (record.kind === 'Presence') {
               const date = record.createdAt.slice(0, 10)
               const mode = record.Transaction[0].Presence.find(j => j.Output).mode.toLowerCase()
               const idx = this.records.findIndex(j => j.type === 'Bar')
               const bar = this.records[idx]
               const transactions = bar.result
               // console.log(`handleRecord ( mode = ${mode} )`, JSON.parse(JSON.stringify(transactions)))
               const transaction = transactions.find(j => j.date === date)
               if (transaction) {
                  if (transaction[mode]) transaction[mode] = parseInt(transaction[mode]) + 1
                  else transaction[mode] = 1
               } else {
                  transactions.push({ date, [mode]: 1 })
                  transactions.splice(0, transactions.length - 7)
               }
               // console.log('handleRecord (bar)', JSON.parse(JSON.stringify(bar)))
               this.records.splice(idx, 1, bar)
            }
         })
      },
   },
}
</script>

<style lang="scss" scoped>
.pull-top {
   margin-top: -92px;
}
.space-left {
   padding-left: 136px !important;
   height: 90px;
}
</style>
